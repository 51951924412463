import { Container } from "react-bootstrap";

const ThanksSecondEn = () => {
    return (
      <Container>
        <h1>Thank You</h1>
        <p>You will immediately receive your files to your email.</p>
      </Container>
    );
  };
  
export default ThanksSecondEn;  