import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";

import LayoutEs from "./pages/LayoutEs";
import ThanksFirstEs from "./pages/ThanksFirstEs";
import ThanksSecondEs from "./pages/ThanksSecondEs";
import TermsOfServiceEs from "./pages/TermsOfServiceEs";
import PrivacyPolicyEs from "./pages/PrivacyPolicyEs";
import HomeEs from "./pages/HomeEs";
import NoPageEs from "./pages/NoPageEs";

import LayoutEn from "./pages/LayoutEn";
import ThanksFirstEn from "./pages/ThanksFirstEn";
import ThanksSecondEn from "./pages/ThanksSecondEn";
import TermsOfServiceEn from "./pages/TermsOfServiceEn";
import PrivacyPolicyEn from "./pages/PrivacyPolicyEn";
import HomeEn from "./pages/HomeEn";
import NoPageEn from "./pages/NoPageEn";

import worldmap from "./world-map3.png";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<Home />} />
        </Route>
        <Route path="/es/" element={<LayoutEs />}>
          <Route index element={<HomeEs />} />
          <Route path="thanks-first" element={<ThanksFirstEs />} />
          <Route path="thanks-second" element={<ThanksSecondEs />} />
          <Route path="terms" element={<TermsOfServiceEs />} />
          <Route path="privacy" element={<PrivacyPolicyEs />} />
          <Route path="*" element={<NoPageEs />} />
        </Route>
        <Route path="/en/" element={<LayoutEn />}>
          <Route index element={<HomeEn />} />
          <Route path="thanks-first" element={<ThanksFirstEn />} />
          <Route path="thanks-second" element={<ThanksSecondEn />} />
          <Route path="terms" element={<TermsOfServiceEn />} />
          <Route path="privacy" element={<PrivacyPolicyEn />} />
          <Route path="*" element={<NoPageEn />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
