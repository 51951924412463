import Container from "react-bootstrap/Container";

const TermsOfServiceEs = () => {
  return (
    <>
    <Container>
    <h1>Terms and Conditions</h1>
    <p>
      Please read these Terms of Use (“Terms”, “Terms of Use”) carefully before using the https://algoscoder.com website (the “Service”) operated by Algos Coder (“us”, “we”, or “our”).

Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.

By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.
    </p>
    <p>Accounts</p> 
    <p>
    When you create an account with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our Service.

You are responsible for safeguarding the password that you use to access the Service and for any activities or actions under your password, whether your password is with our Service or a third-party service.

You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
    </p>
    <p>
      Intellectual Property
    </p>
    <p>
    The Service and its original content, features and functionality are and will remain the exclusive property of Trading SM and its licensors.
    </p>
    <p>
      Links to Other Websites
    </p>
    <p>
    Our Service may contain links to third-party web sites or services that are not owned or controlled by Trading SM.

AlgosCoder.com has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Trading SM shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.

We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
    </p>
    <p>
      Termination
    </p>
    <p>
    We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.

All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.

We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.

Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.

All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
    </p>
    <p>
      Disclaimer
    </p>
    <p>
    Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
    </p>
    <p>
      Governing Law
    </p>
    <p>
    These Terms shall be governed and construed in accordance with the laws of Spain without regard to its conflict of law provisions.

Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
    </p>
    <p>
      Programming Service
    </p>  
    <p>
    The payment for any programming service in this website is made in two steps: 25% to book the code creation service and 75% when the code is done and prior to receive the item (file).

Once the first payment has been completed, the customer adquires the obligation to pay the remaining 75% balance when the project had been finished. Once the first payment had been completed, the customer won’t be able to cancel the project and won’t be able to get a refund and adquires the obligation to pay the remaining 75% balance.

The item, any demo of the item and proof of the item won’t be received prior to the second (75%) payment. Doing so could lead to customers that won’t pay if the trading robot is not profitable enough. Trading SM creates trading robots based on the customer requirements and design. Trading SM does not provide any investments advice and will only create trading robots based on the customer description. Trading SM is not responsible of the profitablily of the trading robot and the customer has the obligation to pay for the service whether the trading robot is profitable or not.

After the second payment, Trading SM will provide support to the customer in order to ammend any features of the item that aren’t as described by the customer prior to make the first payment and received the acceptance from the Trading SM side.

Trading SM commits to create a product as described by the customer in the form available for that purpose. Any modifications of the description after the email sent from Trading SM indicating the price for the service won’t be considered for support purposes.

This is a programming service and so, no refund will be possible unless the customer had made the two payments and after a period of two months, the customer hadn’t received an item as described in the first email.

The customer will receive an email that will content the price and delivery date. Trading SM will do its best to delivery the item at that date but estimating completion time in software development is difficult and could lead to delays that are not subject to refunds or considered part of the item description. Trading SM does not refund any of the two payments if the item is not ready by the date indicated.
    </p>
    <p>
      Changes
    </p>
    <p>
    We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
    </p>
    <p>
      Contact Us
    </p>
    <p>
    If you have any questions about these Terms, please contact us.
    </p>
    </Container>
    </>
    );
  };
  
export default TermsOfServiceEs;  