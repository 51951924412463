import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";


const Home = () => {

    return (
      <Container fluid className=" theme-main-back">
        <div className="p-5 mb-5">
          <p className="text-center h1 theme-contrast-color">
            <span className="theme-remarks-color">Algos</span>Coder
            <span className="theme-remarks-color theme-extra-size">.</span>com
          </p>
        </div>
        <div className="text-center p-3 m-3">
          <Link to="/en/" role="button" className="btn btn-outline-primary">En | English</Link>
        </div>
        <hr></hr>
        <div className="text-center p-3 m-3">
          <Link to="/es/" role="button" className="btn btn-outline-primary">Es | Español</Link>
        </div>
      </Container>
      );
  };
  
  export default Home;  