import { Container } from "react-bootstrap";

const ThanksFirstEn = () => {
    return (
      <Container>
        <h1>Thank You</h1>
        <p>Now, it's my turn. I start to work in your project. I will keep you posted!</p>
      </Container>
    );
  };
  
export default ThanksFirstEn;  