import Container from "react-bootstrap/Container";

const ThanksFirstEs = () => {
  return (
    <Container>
      <h1>Gracias</h1>
      <p>Ahora es mi turno. Comienzo tu proyecto. ¡Te mantendré al día!</p>
    </Container>
  );
  };
  
export default ThanksFirstEs;  