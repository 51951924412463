import Container from "react-bootstrap/Container";

const ThanksSecondEs = () => {
  return (
    <Container>
      <h1>Gracias</h1>
      <p>Recibirás en tu correo los archivos inmediatamente.</p>
    </Container>
  );
  };
  
export default ThanksSecondEs;  