import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import bearspower from "../bearspower.png";

const HomeEs = () => {
  return (
    <>
    <Navbar className="navbar-expand fixed-top" bg="white">
      <Container>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link href="#home">Inicio</Nav.Link>
            <Nav.Link href="#services">Servicios</Nav.Link>
            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link href="#contact">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container className="theme-main-back">
      <Container id="home" className="p-5 mb-5">
        <p className="text-center h1">
          Hola, soy Carlos
        </p>
        <p className="text-center h1 mb-5">
          <span className="theme-remarks-color theme-bold">Programador de Algoritmos</span>.
        </p>
        <p className="text-center mb-5">Tu socio tecnológico.</p>
        <p className="text-center">No pierdas más tiempo en la pantalla. Deja que tus robots ejecuten tus ideas de trading.</p>
        <p className="text-center mb-2">Elimina las imperfecciones antes de ir al mercado.</p>
        <p className="text-center theme-remarks-color">WORLDWIDE<span className="theme-contrast-color theme-medium-extra-size">.</span></p>
      </Container>
      <hr></hr>
      <Container id="services" className="mb-5">
        <h2 className="theme-remarks-color text-center p-5">Servicios</h2>
        <Container className="border border-warning bg-light small">
          <p className="theme-contrast-color fw-bold">IMPORTANTE</p>
          <p>Servicios que <span className="theme-remarks-color fw-bold">no</span> ofrezco:</p>
          <p>No soy asesor financiero. Nunca te diré qué comprar y cuándo. Solo ofrezco servicios de programación y de asesoría tecnológica.</p>
        </Container>
        <Row>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">MQL4</p>
            <p className="fw-light">El lenguaje de MT4.</p>
            <p>EAs, indicadores y scripts para MT4.</p>
          </Col>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">MQL5</p>
            <p className="fw-light">El lenguaje de MT5.</p>
            <p>EAs, indicadores y scripts para MT5.</p>
          </Col>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">C++</p>
            <p className="fw-light">El lenguaje de los DLLs.</p>
            <p>Cuando necesitas algo fuera de MT4/5.</p>
          </Col>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">Python</p>
            <p className="fw-light">El lenguaje de IA.</p>
            <p>Herramientas de investigación a medida.</p>
          </Col>
        </Row>
        <p className="pt-5">
        He creado muchos algoritmos de trading pero también indicadores y herramientas de trading. Entre mis clientes se encuentran traders (profesionales y principiantes), traders de MetaTrader, gestores, vendededores de EAs e indicadores, y otros traders de diferentes plataformas.
        </p>
        <p>
        Doy un <span className="fw-bold theme-remarks-color">servicio de programación</span> rápido que permite a mis clientes enfocarse en sus actividades de trading mientras yo les suministro las automaticaciones que cumplen con sus requisitos.
        </p>
        <p>
        ¿Necesitas un <span className="fw-bold theme-remarks-color">Expert Advisor</span>? ¿Un <span className="fw-bold theme-remarks-color">Custom Indicator</span>? ¿Quizás un <span className="fw-bold theme-remarks-color">Script</span>? ¿Cualquier otra herramienta que automatice tus tareas? ¿Parte de código que necesitas para continuar?
        </p>
        <p>
        Ofrezco soporte después de la entrega y el código fuente de cada programa.
        </p>
      </Container>
      <hr className="m-5"></hr>
      <Container id="portfolio">
        <h2 className="theme-remarks-color text-center p-5">Portfolio Gratis</h2>
        <Row>
          <Col sm={6} className="text-center mb-5">
            <img src={bearspower} alt="Bears Power Cross" width="80%" ></img> 
            <p className="theme-contrast-color h5 fw-bold m-3">Risk Manager</p>
            <p>La precisión es la clave para mejores resultados.</p>
            <a target="_blank" href="" className="theme-remarks-color">Descarga</a>
          </Col>
          <Col sm={6} className="text-center mb-5">
            <img src={bearspower} alt="Bears Power Cross" width="80%" ></img> 
            <p className="theme-contrast-color h5 fw-bold m-3">Indicators Manager</p>
            <p>Mantén tus indicadores y gráficos limpios.</p>
            <a target="_blank" href="" className="theme-remarks-color">Descarga</a>
          </Col>
        </Row>
        
      </Container>
      <hr className="m-5"></hr>
      <p className="mt-5 text-center">
        Hacer trading con algoritmos tiene la ventaja de ejecutar varios indicadores a una velocidad con la que el ser humano no puede competir. Al ejecutarse las operaciones más rápido, se presentan más oportunidades a mejores precios.
      </p>
      <hr className="m-5"></hr>
      <Container id="contact">
        <h2 className="theme-remarks-color text-center p-5">Contact</h2>
        <p>
        Pide presupuesto aquí.
        </p>
        <p>
        Si necesitas adjuntar archivos o imágenes de tu estrategia, puedes enviarme un correo o un mensaje por telegram. Te responderé lo antes posible. 
        </p>
        <p className="text-center">
          Email: <span className="theme-remarks-color">carlos@algoscoder.com</span>
        </p>
        <p className="text-center">
          Telegram: <span className="theme-remarks-color">@AlgosCoder</span> (<a target="_blank" href="https://t.me/AlgosCoder" className="theme-contrast-color">https://t.me/AlgosCoder</a>)
        </p>
      </Container>
      <Container id="footer" className="great-margin-top p-3 text-center">
        <Row>
          <Col sm={6} className="small">@AlgosCoder.com</Col>
          <Col sm={3}>
            <Link to="terms" target="_blank" className="small theme-contrast-color">Términos del Servicio</Link>
          </Col>
          <Col sm={3}>
            <Link to="privacy" target="_blank" className="small theme-contrast-color">Política de Privacidad</Link>
          </Col>
        </Row>
      </Container>
    </Container>
    </>
    );
};
  
export default HomeEs;  