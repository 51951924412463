import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import bearspower from "../bearspower.png";

const HomeEn = () => {
  return (
    <>
    <Navbar className="navbar-expand fixed-top" bg="white">
      <Container>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#portfolio">Portfolio</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Container className="theme-main-back">
      <Container id="home" className="p-5 mb-5">
        <p className="text-center h1">
          Hello, I am Carlos
        </p>
        <p className="text-center h1 mb-5">
          an <span className="theme-remarks-color theme-bold">Algorthims Coder</span> from Spain.
        </p>
        <p className="text-center mb-5">Your tech partner.</p>
        <p className="text-center">No more time at your screen. Let your robots perform with your trading ideas.</p>
        <p className="text-center mb-2">Remove any flaws of a trading idea before you run it live.</p>
        <p className="text-center theme-remarks-color">WORLDWIDE<span className="theme-contrast-color theme-medium-extra-size">.</span></p>
      </Container>
      <hr></hr>
      <Container id="services" className="mb-5">
        <h2 className="theme-remarks-color text-center p-5">Services</h2>
        <Container className="border border-warning bg-light small">
          <p className="theme-contrast-color fw-bold">IMPORTANT NOTICE</p>
          <p>Services I <span className="theme-remarks-color fw-bold">do not</span> provide:</p>
          <p>I am not a financial advisor. I will never advice you what to trade and when. I only provide coding services and technological consultancy and solutions.</p>
        </Container>
        <Row>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">MQL4</p>
            <p className="fw-light">The language of MT4.</p>
            <p>EAs, indicators and scripts for MT4.</p>
          </Col>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">MQL5</p>
            <p className="fw-light">The language of MT5.</p>
            <p>EAs, indicators and scripts for MT5.</p>
          </Col>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">C++</p>
            <p className="fw-light">The language of DLLs.</p>
            <p>When you need something outside MT4/5.</p>
          </Col>
          <Col lg className="text-center p-3">
            <p className="fw-bold theme-remarks-color">Python</p>
            <p className="fw-light">The language of AI.</p>
            <p>Custom tailored research tools.</p>
          </Col>
        </Row>
        <p className="pt-5">
        I have created many trading algorithms but also indicators and trading tools for traders. Among my clients are traders (professionals and beginners), MetaTrader traders, money managers, EAs and indicators sellers, and many other traders of different platforms.
        </p>
        <p>
        I provide a fast <span className="fw-bold theme-remarks-color">Coding Service</span> which allows my clients to focus on their core trading activities while I provide the Expert Advisors, indicators and scripts that are tailored to fit their specific requirements.
        </p>
        <p>
        Do you need an <span className="fw-bold theme-remarks-color">Expert Advisor</span>? A <span className="fw-bold theme-remarks-color">Custom Indicator</span>? Maybe a <span className="fw-bold theme-remarks-color">Script</span>? Anything you want to automate in your daily trading tasks? A part of your program you need help to continue?
        </p>
        <p>
        I provide support after delivery and source code of your software.
        </p>
      </Container>
      <hr className="m-5"></hr>
      <Container id="portfolio">
        <h2 className="theme-remarks-color text-center p-5">Free Portfolio</h2>
        <Row>
          <Col sm={6} className="text-center mb-5">
            <img src={bearspower} alt="Bears Power Cross" width="80%" ></img> 
            <p className="theme-contrast-color h5 fw-bold m-3">Risk Manager</p>
            <p>Accuracy is a key to a better results.</p>
            <a target="_blank" href="" className="theme-remarks-color">Download</a>
          </Col>
          <Col sm={6} className="text-center mb-5">
            <img src={bearspower} alt="Bears Power Cross" width="80%" ></img> 
            <p className="theme-contrast-color h5 fw-bold m-3">Indicators Manager</p>
            <p>Keep your indicators and charts clean.</p>
            <a target="_blank" href="" className="theme-remarks-color">Download</a>
          </Col>
        </Row>
        
      </Container>
      <hr className="m-5"></hr>
      <p className="mt-5 text-center">
        Trading with algorithms has the advantage of scanning and executing on multiple indicators at a speed that no human could do. Since trades can be analyzed and executed faster, more opportunities are available at better prices.
      </p>
      <hr className="m-5"></hr>
      <Container id="contact">
        <h2 className="theme-remarks-color text-center p-5">Contact</h2>
        <p>
        Get a quote here.
        </p>
        <p>
        If you need to send files or images describing your strategy, just send me an email or telegram message, and I will come back to you as soon as possible.
        </p>
        <p className="text-center">
          Email: <span className="theme-remarks-color">carlos@algoscoder.com</span>
        </p>
        <p className="text-center">
          Telegram: <span className="theme-remarks-color">@AlgosCoder</span> (<a target="_blank" href="https://t.me/AlgosCoder" className="theme-contrast-color">https://t.me/AlgosCoder</a>)
        </p>
      </Container>
      <Container id="footer" className="great-margin-top p-3 text-center">
        <Row>
          <Col sm={6} className="small">@AlgosCoder.com</Col>
          <Col sm={3}>
            <Link to="terms" target="_blank" className="small theme-contrast-color">Terms of Service</Link>
          </Col>
          <Col sm={3}>
            <Link to="privacy" target="_blank" className="small theme-contrast-color">Privacy Policy</Link>
          </Col>
        </Row>
      </Container>
    </Container>
    </>
    );
};
  
export default HomeEn;  